.app-list-builder-base {
  display: flex;
  justify-items: stretch;
  width: 1000px;
  margin-top: 10px;
}
.app-list-builder-base > .builder-buttons {
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  justify-content: center;
}
.app-list-builder-base > .builder-buttons > button {
  margin: 0;
}
.app-list-builder-base > .builder-buttons > button + button {
  margin-top: 12px;
}

.app-list-builder-base > .builder-list-wrapper {
  flex-grow: 1;
  flex-basis: 0;
  border: 1px solid #eaedf3;
  border-radius: 4px;
  background-color: #fff;
  position: relative;
}
.app-list-builder-base > .builder-list-wrapper.drag-over {
  border-color: #005794;
}

.app-list-builder-base > .builder-list-wrapper > h3 {
  background-color: rgb(243, 245, 245);
  margin: 0;
  padding: 16px;
  font-size: 16px;
}
.app-list-builder-base > .builder-list-wrapper > h3 > button {
  position: absolute;
  right: 0;
  margin: -10px 6px 0 0;
}

.app-list-builder-base > .builder-list-wrapper > ul {
  list-style: none;
  margin: 0;
  padding: 12px 0 0;

  overflow-y: auto;
  height: calc(100vh - 335px);
}
.app-list-builder-base > .builder-list-wrapper > ul.with-list-buttons {
  height: calc(100vh - 400px);
}

.app-list-builder-base > .builder-list-wrapper > ul > li {
  padding: 10px 0 10px 16px;
  cursor: pointer;
}
.app-list-builder-base > .builder-list-wrapper > ul > li.drag-over {
  box-shadow: 0 1px #005794 inset;
}
.app-list-builder-base > .builder-list-wrapper > ul > li:hover {
  background-color: #f0f1f3;
}
.app-list-builder-base > .builder-list-wrapper > ul > li.selected {
  background-color: rgba(2, 74, 148, 0.1);
}
.app-list-builder-base
  > .builder-list-wrapper
  > ul
  > li.drop-placeholder:hover {
  background-color: #fff;
  cursor: default;
}

.app-list-builder-base > .builder-list-wrapper .list-buttons {
  display: flex;
  width: 100%;
  border-top: 1px solid #eaedf3;
  background-color: #fff;
}
.app-list-builder-base > .builder-list-wrapper .list-buttons button {
  margin: 20px 0 20px 20px;
}

.app-list-builder-base > .builder-list-wrapper .list-buttons .right {
  margin-right: 20px;
  text-align: right;
  flex-grow: 1;
}
