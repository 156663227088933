body.PrintableEntryBody {
  margin: 0;
}
@page {
  size: A4;
}
.PrintableEntry {
  background-color: #f3f3f3;
  width: 794px;
  height: 1123px;
}
.PrintableImagePage {
  height: 100%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}
.PrintableImagePage.hidden {
  display: none;
}
.PrintableImagePage:not(:last-child) {
  margin-bottom: 30px;
}
.PrintableComponent {
  background-color: transparent;
  position: absolute;
  overflow: hidden;
}
.printable-entry-title {
  display: flex;
  justify-content: space-between;
}
.printable-entry-title-span {
  font-size: 30px;
  font-weight: 600;
}
.printable-entry-controls {
  display: flex;
}

@media print {
  .npxLayout-0-2-10 {
    padding-top: 0 !important;
    padding-left: 0 !important;
  }
  .cpcs-navbar {
    display: none !important;
  }
  .nxpLayoutGlobal > nav {
    display: none !important;
  }
  .PrintableEntry {
    padding-right: 30px !important;
  }
  .printable-entry-title {
    display: none !important;
  }
  .PrintableImagePage {
    transform: scale(1.2) translate(8.5%, 10%) !important;
    page-break-after: always !important;
  }
  .PrintableImagePage.hidden {
    display: block !important;
  }
}
