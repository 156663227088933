.chats-tab-container {
  height: auto;
  width: 100%;
}

.chats-tab-container .messages {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  padding: 20px 0 80px 0;
}
.chats-tab-container .messages .message {
  width: calc(100% - 20px);
  display: flex;
  margin-bottom: 20px;
}
.chats-tab-container .messages .message.user {
  flex-direction: row-reverse;
  margin-right: 20px;
}
.chats-tab-container .messages .message.user .profile {
  margin-left: 10px;
}
.chats-tab-container .messages .message.not-user {
  flex-direction: row;
  margin-left: 160px;
}
.chats-tab-container .messages .message.not-user .profile {
  margin-right: 10px;
}
.chats-tab-container .messages .message .profile-wrapper .profile {
  width: 50px;
  border-radius: 25px;
  text-align: center;
  padding-top: 15.5px;
  padding-bottom: 15.5px;
  color: #fff;
}
.chats-tab-container .messages .message .profile-wrapper .profile.color0 {
  background-color: #34568b;
}
.chats-tab-container .messages .message .profile-wrapper .profile.color1 {
  background-color: #ff6f61;
}
.chats-tab-container .messages .message .profile-wrapper .profile.color2 {
  background-color: #6b5b95;
}
.chats-tab-container .messages .message .profile-wrapper .profile.color3 {
  background-color: #88b04b;
}
.chats-tab-container .messages .message .profile-wrapper .profile.color4 {
  background-color: #955251;
}
.chats-tab-container .messages .message .profile-wrapper .profile.color5 {
  background-color: #efc050;
}
.chats-tab-container .messages .message .profile-wrapper .profile.color6 {
  background-color: #009b77;
}
.chats-tab-container .messages .message .profile-wrapper .profile.color7 {
  background-color: #798ea4;
}
.chats-tab-container .messages .message .profile-wrapper .profile.color8 {
  background-color: #a2242f;
}
.chats-tab-container .messages .message .profile-wrapper .profile.color9 {
  background-color: #343148;
}
.chats-tab-container .messages .message .bubble {
  border: 1px solid #8a9999;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 20px 15px 20px;
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 270px);
}
.chats-tab-container .messages .message .bubble .meta {
  display: flex;
  align-items: baseline;
}
.chats-tab-container .messages .message .bubble .meta .author {
  font-size: 12px;
  font-weight: 900;
}
.chats-tab-container .messages .message .bubble .meta .author.color0 {
  color: #34568b;
}
.chats-tab-container .messages .message .bubble .meta .author.color1 {
  color: #ff6f61;
}
.chats-tab-container .messages .message .bubble .meta .author.color2 {
  color: #6b5b95;
}
.chats-tab-container .messages .message .bubble .meta .author.color3 {
  color: #88b04b;
}
.chats-tab-container .messages .message .bubble .meta .author.color4 {
  color: #955251;
}
.chats-tab-container .messages .message .bubble .meta .author.color5 {
  color: #efc050;
}
.chats-tab-container .messages .message .bubble .meta .author.color6 {
  color: #009b77;
}
.chats-tab-container .messages .message .bubble .meta .author.color7 {
  color: #798ea4;
}
.chats-tab-container .messages .message .bubble .meta .author.color8 {
  color: #a2242f;
}
.chats-tab-container .messages .message .bubble .meta .author.color9 {
  color: #343148;
}
.chats-tab-container .messages .message .bubble .meta .timestamp {
  font-size: 10px;
  color: #8a9999;
  margin-left: 20px;
}
.chats-tab-container .messages .message .bubble .text {
  width: 100%;
}
.chats-tab-container .messages .message .bubble .text .highlighted {
  background-color: rgba(0, 87, 148, 0.25);
}
.chats-tab-container .new-message-wrapper {
  position: fixed;
  bottom: 0;
  height: 90px;
  width: calc(100% - 295px);
  left: 200px;
  background-color: #f8f8fd;
}
.chats-tab-container .new-message-wrapper .new-message {
  position: relative;
  display: flex;
  width: 100%;
}
.chats-tab-container .new-message-wrapper .new-message .message-input {
  width: 95%;
  background-color: #fff;
}

.chats-tab-container .new-message-wrapper .new-message .send-button {
  height: 40px;
  width: 65px;
  background-color: #005794;
  color: #fff;
  font-weight: 900;
  margin-left: 8.5px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.chats-tab-container .new-message-wrapper .new-message .post-file-modal-button {
  height: 40px;
}
.chats-post-file-wrapper {
  display: flex;
}
.chats-post-file-wrapper .file-uploader-container {
  flex-grow: 1;
}

.chats-post-file-wrapper .chats-post-file-label {
  margin: 7px 7px 0 0;
  color: rgb(138, 153, 153);
  font-size: 0.7rem;
  font-weight: 500;
  text-transform: uppercase;
}
