.form-printable-body {
  display: flex;
  flex-direction: column;
}

.form-printable-container {
  display: flex;
  padding: 0 40px;
  background-color: #fff;
}

.form-printable-page {
  width: 100%;
  margin-top: 10px;
  padding: 30px 60px 10px 30px;
  background-color: #fff;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.form-printable-page-details {
  width: 80%;
}

.form-printable-left-panel {
  flex: 15%;
  margin: 20px;
  padding-top: 58px;
}

.form-printable-left-panel-inner {
  height: 80vh;
  overflow: auto;
}

.form-printable-drag-header {
  display: flex;
  justify-content: space-between;
}

.form-printable-drag-title {
  display: flex;
}

.form-printable-component {
  background: #1872d6 !important;
  color: white !important;
  flex-direction: column !important;
  display: flex !important;
  justify-content: center !important;
  border: solid 1px #ddd !important;
  position: relative;
}

.form-printable-exist-component {
  background: #ffedc0 !important;
  color: #605e5c !important;
  flex-direction: column !important;
  display: flex !important;
  justify-content: center !important;
  border: solid 2px #1872d6 !important;
  position: relative;
}

.form-printable-exist-component-name {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  color: #605e5c;
  padding: 2px 10px;
  font-size: 12px;
}

.form-printable-exist-component-type {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  color: #605e5c;
  padding: 2px 10px;
  font-size: 12px;
}

.form-printable-exist-component-corner {
  position: absolute;
  bottom: -10px;
  right: -10px;
  background: white;
  padding: 1px;
  height: 20px;
  width: 20px;
  border: solid 1px black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-printable-exist-component-corner img {
  width: 10px;
  height: 10px;
}

.form-printable-exist-component:hover {
  z-index: 1;
}

.form-printable-exist-component:hover .form-printable-component-tool {
  display: block;
}

.form-printable-component-name {
  display: block;
  background: #1872d6 !important;
  color: white !important;
  padding: 2px 10px;
  font-size: 16px;
}

.form-printable-component-type {
  display: block;
  background: #1872d6 !important;
  color: white !important;
  padding: 2px 10px;
  font-size: 12px;
}

.form-printable-component-edit {
  background: #1872d6;
  color: white;
  width: 34px;
  height: 34px;
  position: absolute;
  bottom: 0;
  left: 20px;
}

.form-printable-component-delete {
  background: white;
  color: #1872d6;
  width: 34px;
  height: 34px;
  position: absolute;
  bottom: 0;
  left: 64px;
}

.form-printable-component-tool {
  display: none;
  height: 50px;
  width: 130px;
  position: absolute;
  bottom: 0;
  right: -130px;
  cursor: default;
}

.form-printable-main {
  flex: 85%;
  padding: 20px;
}

.form-upload-image {
  width: 794px;
  height: 1123px;
  border-radius: 5px;
  border: solid 1px #979797;
  background-color: #f3f3f3;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.form-upload-image-overlay {
  position: fixed;

  display: flex;
  justify-content: center;
  align-items: center;
}

.form-printable-component-tool img {
  width: 100%;
}

.form-upload-image-btn {
  padding: 0;
  font-size: 100px;
  color: #acacac;
  background-color: transparent;
  border: 0;
  height: 140px;
  cursor: pointer;
}

.form-upload-image-text {
  display: block;
  font-family: SegoeUI, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #acacac;
}

.form-printable-action-bar {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  font-family: SegoeUI, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.form-printable-action-button {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.form-printable-action-button-inner {
  display: flex;
}

.form-printable-action-new-page {
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  width: 151px;
  height: 45px;
  border-radius: 2px;
  border: 1px solid #0078d4;
  background-color: white;
  font-size: 14px;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: normal;
  text-align: center;
  color: #0078d4;
  margin: 0 10px;
}

.form-printable-action-reload-image-hidden-input {
  cursor: pointer;
  outline: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
}

.form-printable-action-reload-image {
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  width: 151px;
  height: 45px;
  border-radius: 2px;
  border: 1px solid #0078d4;
  background-color: white;
  font-size: 14px;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: normal;
  text-align: center;
  color: #0078d4;
  margin: 0 10px;
}

.form-printable-action-new-page-hidden-input {
  cursor: pointer;
  outline: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
}

.form-printable-action-create {
  width: 140px;
  height: 45px;
  border-radius: 2px;
  background-color: #0078d4;
  text-align: center;
  color: #ffffff;
  font-size: 14px;
}

.form-printable-action-discard-btn {
  width: 140px;
  height: 45px;
  border-radius: 2px;
  background-color: #dc3545;
  text-align: center;
  color: #ffffff;
  font-size: 14px;
  margin: 0 10px 0 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 15px;
}

.form-upload-image > input[type="file"] {
  cursor: pointer;
  outline: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
}

.form-printable-list-pagination {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.form-printable-page-control {
  background: white;
  border-radius: 2px;
  border: solid 1px #a19f9d;

  display: flex;
  align-items: center;
  margin: 0 0 0 5px;
}

.form-printable-page-control-sep {
  background: #979797;
  height: 16px;
  width: 1px;
}

.form-printable-page-control-prev.is-disabled,
.form-printable-page-control-next.is-disabled {
  color: #acacac;
  background-color: #ffffff;
  cursor: default !important;
}

.form-printable-list-pagination-page {
  margin-right: 10px;
}

.form-preview-image {
  width: 794px;
  height: 1123px;
  overflow: hidden;
  border-radius: 5px;
  border: solid 1px #979797;
  background-color: #fff;
}

.form-preview-image > img {
  width: 100%;
}

.status-greytext {
  color: #8a9999;
}

.status-redtext {
  color: #dc3545;
}

.status-greentext {
  color: #28a745;
}
