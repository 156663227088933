.file-uploader-container .label {
  font-size: 14px;
  font-weight: 600;
}

.file-uploader-container.error .uploader {
  border-color: #ff4141;
}

.file-uploader-container .preview-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, 73.6px);
  grid-template-rows: 1fr;
  grid-column-gap: 12px;
}

.file-uploader-container .preview-list-item {
  margin-bottom: 12px;
}

.file-uploader-container .preview-list-item .preview-list-item__label {
  margin-top: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
}

.file-uploader-container .uploader {
  height: 73.6px;
  width: 73.6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: solid 1px rgb(188, 203, 203);
  color: rgb(0, 87, 148);
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
}
.file-uploader-container .uploader .anticon-download {
  margin-right: 8px;
}
.file-info {
  display: flex;
  margin-bottom: 0;
}
.file-info i {
  margin-right: 5px;
}
.file-info a {
  font-size: 16px;
  font-weight: bold;
  flex-grow: 1;
}
.file-info button.remove {
  flex-grow: 0;
  background: none;
  cursor: pointer;
  border: none;
  color: #005794;
  font-weight: bold;
}

.file-uploader-container button.remove:hover {
  background: #eaedf3;
}

.file-uploader-container .file-uploader-container .label,
.file-uploader-container .file-info-label {
  text-transform: uppercase;
  color: #8a9999;
  font-size: 10px;
  font-weight: bold;
}

.file-uploader-container .preview-list {
  display: block;
}

.file-uploader-container .uploader {
  width: 100%;
  height: 32px;
  flex-direction: row;
  text-transform: uppercase;
  font-weight: 500;
}

.file-uploader-container .uploader img {
  height: 16px;
  margin-right: 10px;
}

.file-uploader-container .error > p {
  color: rgb(164, 38, 44);
  font-size: 12px;
  margin: 6px 0 0;
}
